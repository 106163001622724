@tailwind base;
@tailwind components;
@tailwind utilities;

/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #edf2f7;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #ffa200;
    border-radius: 100vh;
    border: 3px solid #edf2f7;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #FF8300;
}

.dir-ltr {
    direction: ltr;
}

.ant-switch {
    @apply bg-slate-200
}

.ant-switch-checked {
    @apply bg-primary
}

.ant-popover-inner {
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.08), 0 2px 4px -2px rgba(0, 0, 0, 0.12), 0 6px 14px 4px rgba(0, 0, 0, 0.05) !important;
}

.ant-picker-ok > .ant-btn-primary {
    @apply bg-primary
}


.ant-picker-now-btn {
    color: white;
}

.ant-picker-ranges {
    background-color: #fb923c;
}